<template>
    <el-dialog :visible.sync="showDialog"
        width="570px"
        center
        custom-class="edit-dialog__wrap"
        :close-on-click-modal="false"
        @close="resetRuleForm">
        <div slot="title">
            <div class="title">{{ ruleForm.id ? 'Edit' : 'Add' }} address</div>
            <div class="text">Please fill in the information below</div>
        </div>
        <div class="content-wrap">
            <el-form ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                :disabled="isLoading">
                <el-row :gutter="12">
                    <el-col :span="12">
                        <el-form-item prop="firstName">
                            <el-input v-model="ruleForm.firstName"
                                size="''"
                                placeholder="First name"
                                clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="lastName">
                            <el-input v-model="ruleForm.lastName"
                                size="''"
                                placeholder="Last name"
                                clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item prop="company">
                    <el-input v-model="ruleForm.company"
                        size="''"
                        placeholder="Company"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-form-item prop="phone">
                    <el-input v-model="ruleForm.phone"
                        size="''"
                        placeholder="Phone"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-form-item prop="address">
                    <el-input v-model="ruleForm.address"
                        size="''"
                        placeholder="Address"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-row :gutter="12">
                    <el-col :span="12">
                        <el-form-item prop="city">
                            <el-input v-model="ruleForm.city"
                                size="''"
                                placeholder="City"
                                clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="code">
                            <el-input v-model="ruleForm.code"
                                size="''"
                                placeholder="Zip Code"
                                clearable>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item prop="country">
                    <el-select v-model="ruleForm.country"
                        size="''"
                        placeholder="Country"
                        clearable
                        filterable
                        @change="handleSelCountry">
                        <el-option v-for="(item, index) in countrys"
                            :key="index"
                            :label="item.en_name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="province">
                    <el-select v-model="ruleForm.province"
                        size="''"
                        placeholder="Province"
                        clearable
                        filterable
                        :disabled="!(ruleForm.country)">
                        <el-option v-for="(item, index) in provinces"
                            :key="index"
                            :label="item.en_name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div>
                    <el-checkbox v-model="ruleForm.default" :true-label="1" :false-label="0">Set as default payment method</el-checkbox>
                </div>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="''" :loading="isLoading" @click="doSave">SAVE</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: Boolean,
        address: Object
    },
    data () {
        return {
            showDialog: this.visible,
            ruleForm: {
                firstName: '',
                lastName: '',
                company: '',
                phone: '',
                address: '',
                city: '',
                code: '',
                country: '',
                province: ''
            },
            rules: {
                firstName: [{ required: true, message: 'First name is required!', trigger: 'change' }],
                lastName: [{ required: true, message: 'Last name is required!', trigger: 'change' }],
                phone: [{ required: true, message: 'Phone is required!', trigger: 'change' }],
                address: [{ required: true, message: 'Address is required!', trigger: 'change' }],
                city: [{ required: true, message: 'First name is required!', trigger: 'change' }],
                code: [{ required: true, message: 'Zip code is required!', trigger: 'change' }],
                country: [{ required: true, message: 'Country is required!', trigger: 'change' }],
                province: [{ required: true, message: 'Province is required!', trigger: 'change' }]
            },
            countrys: [],
            provinces: [],
            isLoading: false
        }
    },
    watch: {
        visible: {
            handler (val) {
                this.showDialog = val
            },
            immediate: true
        },
        showDialog (nval, oval) {
            this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
            this.$emit('update:visible', nval)
        },
        address: {
            handler (val) {
                if (val && val.id) {
                    this.ruleForm = {
                        id: val.id,
                        firstName: val.first_name,
                        lastName: val.last_name,
                        company: val.company,
                        phone: val.phone,
                        address: val.address,
                        city: val.city,
                        code: val.postal_code,
                        country: val.country_code,
                        province: val.state_or_provice,
                        default: val.default_status
                    }
                    if (val.country_code) {
                        this.reqAllProvinces()
                    }
                }
            },
            immediate: true
        }
    },
    created () {
        this.reqAllCountrys()
    },
    methods: {
        // 请求所有国家
        reqAllCountrys () {
            this.$api.getAllCountrys({}).then(res => {
                if (res.code === 0) {
                    this.countrys = res.data || []
                }
            })
        },
        // 请求所有省份
        reqAllProvinces () {
            this.$api.getAllProvinces({
                country_code: this.ruleForm.country
            }).then(res => {
                if (res.code === 0) {
                    this.provinces = res.data || []
                }
            })
        },
        // 选择国家
        handleSelCountry () {
            this.ruleForm.province = ''
            this.reqAllProvinces()
        },
        // 保存
        doSave () {
            const that = this
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (that.ruleForm.id) {
                        this.reqAddressUpdate()
                    } else {
                        this.reqAddressSave()
                    }
                }
            })
        },
        // 数据重置
        resetRuleForm () {
            this.ruleForm = {
                firstName: '',
                lastName: '',
                company: '',
                phone: '',
                address: '',
                city: '',
                code: '',
                country: '',
                province: '',
                default: 0
            }
            this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
        },
        // 新增地址保存
        reqAddressSave () {
            const data = {
                first_name: this.ruleForm.firstName,
                last_name: this.ruleForm.lastName,
                company: this.ruleForm.company,
                phone: this.ruleForm.phone,
                address: this.ruleForm.address,
                city: this.ruleForm.city,
                postal_code: this.ruleForm.code,
                country_code: this.ruleForm.country,
                state_or_provice: this.ruleForm.province,
                default_status: this.ruleForm.default || 0
            }
            this.isLoading = true
            this.$api.insertAddress(data).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    this.resetRuleForm()
                    this.$emit('update:visible', false)
                    this.$emit('update')
                } else {
                    this.$notify.error(res.message || 'Add Address Error!')
                }
            }).catch(() => {
                this.isLoading = false
                this.$notify.error('Update Error!')
            })
        },
        // 编辑地址保存
        reqAddressUpdate () {
            const data = {
                id: this.ruleForm.id,
                first_name: this.ruleForm.firstName,
                last_name: this.ruleForm.lastName,
                company: this.ruleForm.company,
                phone: this.ruleForm.phone,
                address: this.ruleForm.address,
                city: this.ruleForm.city,
                postal_code: this.ruleForm.code,
                country_code: this.ruleForm.country,
                state_or_provice: this.ruleForm.province,
                default_status: this.ruleForm.default || 0
            }
            this.isLoading = true
            this.$api.updateAddress(data).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    this.resetRuleForm()
                    this.$emit('update:visible', false)
                    this.$emit('update')
                } else {
                    this.$notify.error(res.message || 'Update Address Error!')
                }
            }).catch(() => {
                this.isLoading = false
                this.$notify.error('Update Address Error!')
            })
        }
    }
}
</script>

<style lang="scss">
.edit-dialog__wrap {
    .title {
        font-size: 26px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
        line-height: 37px;
        margin-bottom: 5px;
    }
    .text {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #666666;
    }
    .el-select{
        width: 100%;
    }
    .dialog-footer {
        .el-button{
            width: 100%;
        }
    }
}
</style>
