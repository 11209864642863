<template>
    <div class="address-wrap main-content-wrap">
        <div class="title-wrap">My addresses
            <el-button type="primary"
                size="''"
                v-if="dataList.length"
                @click="doEdit(null)">
                ADD NEW ADDRESS
            </el-button>
        </div>
        <div class="address-list"
            v-if="dataList.length"
            v-loading="isLoading">
            <el-row :gutter="24">
                <el-col :span="8" v-for="(addr, index) in dataList" :key="index">
                    <div class="address-card">
                        <div class="name-phone">
                            <span class="name">{{ addr.first_name }} {{ addr.last_name }}</span>
                            <span class="phone">{{ addr.phone }}</span>
                        </div>
                        <div class="content-wrap">{{ addr.addressStr }}</div>
                        <div class="btn-groups">
                            <el-tag v-if="addr.default_status === 1" size="small" type="success">Default Address</el-tag>
                            <span>
                                <el-button type="text" @click="doRemove(addr)">Remove</el-button>
                                <el-button type="text" @click="doEdit(addr)">Edit</el-button>
                            </span>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <rap-error v-else
            :imgUrl="errorImage"
            title="You have not bound an address"
            btnTitle="Add new address"
            @btnClick="doEdit(null)">
        </rap-error>
        <!-- 新增 | 编辑 -->
        <edit-dialog :visible.sync="showDialog"
            :address="choosedItem"
            @update="reqAddressList">
        </edit-dialog>
    </div>
</template>

<script>
import EditDialog from './edit-dialog'
export default {
    components: {
        EditDialog
    },
    data () {
        return {
            isLoading: false,
            showDialog: false,
            choosedItem: null,
            dataList: [],
            errorImage: require('@/assets/rap/personal/address-empty.svg')
        }
    },
    created () {
        this.reqAddressList()
    },
    methods: {
        // 地址列表
        reqAddressList () {
            this.isLoading = true
            this.$api.getAddressList({}).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    this.dataList = (res.data || []).map(p => {
                        const addressStrs = []
                        if (p.address) {
                            addressStrs.push(p.address)
                        }
                        if (p.city) {
                            addressStrs.push(p.city)
                        }
                        if (p.state_name) {
                            addressStrs.push(p.state_name)
                        }
                        if (p.country_name) {
                            addressStrs.push(p.country_name)
                        }
                        if (p.code) {
                            addressStrs.push(p.code)
                        }
                        return {
                            ...p,
                            addressStr: addressStrs.join(',')
                        }
                    })
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        // 新增 | 编辑
        doEdit (data) {
            this.choosedItem = data
            this.showDialog = true
        },
        // 删除
        doRemove (data) {
            this.$confirm('Are you sure you want to delete this address?', '', {
                confirmButtonText: 'YES',
                cancelButtonText: 'NO',
                type: 'warning'
            }).then(() => {
                this.reqAddressRemove(data)
            })
        },
        // 请求删除
        reqAddressRemove (data) {
            this.isLoading = true
            this.$api.deleteAddress({
                id: data.id
            }).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    this.$notify.success('Remove Success')
                    this.reqAddressList()
                }
            }).catch(() => {
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.address-wrap {
    background: white;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid #dedede;
    border-radius: 2px;
    .title-wrap {
        padding: 24px 24px 15px 24px;
        font-size: 26px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
        border-bottom: 1px solid #dedede;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &.other {
            padding-top: 8px;
        }
    }
    .address-list {
        padding: 20px 24px 0 24px;
        .address-card {
            width: 100%;
            background: #ffffff;
            border: 1px solid #e6e8eb;
            margin-bottom: 20px;
            padding: 20px 24px;
            box-sizing: border-box;
            transition: all .15s ease-in-out;
            &:hover{
                opacity: 0.8;
                transition: all .3s ease-in-out;
            }
            .name-phone {
                margin-bottom: 5px;
                .name {
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    color: #222222;
                    margin-right: 10px;
                }
                .phone {
                    opacity: 0.6;
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    color: #222222;
                }
            }
        }
        .content-wrap {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #222222;
            line-height: 20px;
            height: 40px;
            @include ellipsis(2);
            margin-bottom: 10px;
        }
        .btn-groups {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 22px;
            .el-button{
                padding: 0;
                color: rgba($color: #222222, $alpha: 0.7);
            }
        }
        .el-tag {
            color: #52C41A;
            border-color: #52C41A;
            border-radius: 2px;
        }
    }
}
</style>
